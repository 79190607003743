import React from 'react';
import Layout from '../components/Layout.js';
import Head from '../components/head';
import {books} from '../helpers.js'
import IBooksBadge from "../images/iBooksBadge.svg";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$/));

class HoraExpand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {mainImage: images["HoraShowcase01.jpg"],
                  books

  }
  this.changeImage = this.changeImage.bind(this)
}
  changeImage(e) {
    console.log(e.target.src)
    this.setState({mainImage: e.target.src})
  }

  render() {
    return (
      <Layout>
      <Head title={this.state.books.Hora.title} />
      <div className="showcase-container">
        <div className="main-image-container">
          <img src={this.state.mainImage} />
        </div>
        <div className="bottom-images-container">
          <img onClick={this.changeImage} src={images["HoraShowcase01.jpg"]} />
          <img onClick={this.changeImage} src={images["HoraShowcase02.jpg"]} />
          <img onClick={this.changeImage} src={images["HoraShowcase03.jpg"]} />
          <img onClick={this.changeImage} src={images["HoraShowcase04.jpg"]} />
          <img onClick={this.changeImage} src={images["HoraShowcase05.jpg"]} />
        </div>
      </div>
      <h2 className="tituloDeLibroFullScreen">{this.state.books.Hora.title}</h2>
        <section className="wideExpandTextoSection">
        <p className="section-text">{this.state.books.Hora.summary}
        </p>
      </section>

      <div className="logo-itunes-container">
        <a href={this.state.books.Hora.storeLink}><img src={IBooksBadge} /></a>
      </div>
      </Layout>
    );
  }
}

export default HoraExpand;
